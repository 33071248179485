class UserTrack {
    static settings = {};


    static init(config = {}) {
        UserTrack.settings = config
    }

    /**
     * Verifica se la variabile globale è presente
     * @returns {boolean}
     */
    static checkIfInitialized() {
        return true;
    }
}

export default UserTrack