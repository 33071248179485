class CookieLaw {

    static init() {
        try {
            (function (window) {
                window._iub = window._iub || [];

                window._iub.csConfiguration = {
                    "ccpaAcknowledgeOnDisplay": true,
                    "consentOnContinuedBrowsing": false,
                    "countryDetection": true,
                    "googleAdditionalConsentMode": true,
                    "reloadOnConsent": false,
                    "enableCcpa": true,
                    "enableLgpd": true,
                    "floatingPreferencesButtonDisplay": "bottom-right",
                    "invalidateConsentWithoutLog": true,
                    "lgpdAppliesGlobally": false,
                    "perPurposeConsent": true,
                    "siteId": process.env.IUBENDA_SITE_ID,
                    "whitelabel": false,
                    "cookiePolicyId": process.env.IUBENDA_COOKIE_POLICY_ID,
                    "lang": "it",
                    "banner": {
                        "acceptButtonDisplay": true,
                        "closeButtonDisplay": false,
                        "customizeButtonDisplay": true,
                        "explicitWithdrawal": true,
                        "listPurposes": true,
                        "position": "float-top-center",
                        "rejectButtonDisplay": true
                    },
                    "callback": {
                        onPreferenceExpressedOrNotNeeded: function(preference) {
                            dataLayer.push({
                                iubenda_ccpa_opted_out: _iub.cs.api.isCcpaOptedOut()
                            });
                            if (!preference) {
                                dataLayer.push({
                                    event: "iubenda_preference_not_needed"
                                });
                            } else {
                                if (preference.consent === true) {
                                    dataLayer.push({
                                        event: "iubenda_consent_given"
                                    });
                                } else if (preference.consent === false) {
                                    dataLayer.push({
                                        event: "iubenda_consent_rejected"
                                    });
                                } else if (preference.purposes) {
                                    for (var purposeId in preference.purposes) {
                                        if (preference.purposes[purposeId]) {
                                            dataLayer.push({
                                                event: "iubenda_consent_given_purpose_" + purposeId
                                            });
                                        }
                                    }
                                }
                            }
                        }
                    }
                };
            })(window);

            (function (window, doc, src, j, head, script) {
                head = doc.getElementsByTagName('head')[0];
                script = doc.createElement('script');
                script.defer = true
                script.src = src;
                head.appendChild(script);
            })(window, document, '//cdn.iubenda.com/cs/ccpa/stub.js');

            (function (window, doc, src, j, head, script) {
                head = doc.getElementsByTagName('head')[0];
                script = doc.createElement('script');
                script.async = true;
                script.charset = "UTF-8"
                script.src = src;
                head.appendChild(script);
            })(window, document, '//cdn.iubenda.com/cs/iubenda_cs.js');
        } catch (error) {
            console.error(error);
        }
    }
}

export default CookieLaw