
import axios from "axios";
import GaTrack from "../gaTrack";

class HubSpotConnector {

    static defaultParams = {
        portalId: "8895135",
    };

    static forms = {
        url: 'https://api.hsforms.com/submissions/v3/integration/submit/'
    }

    static sendForm = function (formID,data) {
        const safeData = HubSpotConnector.prepareData(data);
        return axios.post(
            HubSpotConnector.forms.url + HubSpotConnector.defaultParams.portalId + '/' + formID,
            JSON.stringify(safeData),
            {
                cache: false,
                headers: {'Content-Type': 'application/json'}

            }
        ).then(()=>{
            HubSpotConnector.trackFormSend(formID);
        }).catch(reason => {
            let formData = new FormData();
            const data = {
                payload:safeData,
                reason:reason,
                location:window.location
            }
            formData.append('data',JSON.stringify(data))
            // invio dati su API INP
            return axios.post(`${process.env.AJAX_URL}?action=${ERROR_NOTIFY_ACTION}`,formData,
                {
                    cache: false,
                    headers: {'Content-Type': 'multipart/form-data'}
                });
            //console.log(reason);
        })
    }

    static trackFormSend = function(eventLabel = ''){
        GaTrack.sendEventToAnalytics({
            category: 'sito',
            action: 'form-submit',
            label: eventLabel,
            type: 'event',
        })
    }

    static prepareData = function(data){
        //console.log(data)
        let content = {
            "submittedAt": new Date().getTime(),
            "fields": [],
            "context": {
                "pageUri": window.location.href,
                "pageName": document.title
            },
        };
        const hutk = document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*=\s*([^;]*).*$)|^.*$/, "$1");
        if(hutk) content.context.hutk = hutk;


        _.forEach(data, function (value, key) {
            if (_.isObject(value)) {
                //console.log(value)
                //content.fields[key] = [];
                _.forEach(value, function (v, k) {
                    content.fields.push({name: key + "." + k, value: v});
                });
            } else {
                const safeValue = _.isUndefined(value) ? false : value;
                content.fields.push({name: key, value: safeValue});
            }
        });

        return content;
    }
}

export default HubSpotConnector;

