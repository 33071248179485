import axios from 'axios';
import GaTrack from "../gaTrack";


class MailchimpConnector {


    static subscribe(request) {
        request.action = process.env.NEWSLETTER_SUBSCRIBE_ACTION;


        axios({
            method: 'POST',
            url: process.env.AJAX_URL,
            dataType: 'json',
            params: request
        }).then(function (response) {
            //console.log(response);
            GaTrack.sendEventToAnalytics({
                category: 'sito',
                action: 'iscrizione',
                label: 'newsletter',
                type: 'event',
            })

        })
    }
}

export default MailchimpConnector;