
import axios from "axios";
const _ = require("lodash");



import UserTrack from "./userTrack";
import GaTrack from "./gaTrack";
import TagManager from "./TagManager";
import CookieLaw from "./cookieLaw"

import  MailchimpConnector from "./connectors/mailchimp"
import  HubSpotConnector from "./connectors/hubspot"

TagManager.init();

export {
    axios,
    _,
    UserTrack,
    GaTrack,
    TagManager,
    CookieLaw,
    MailchimpConnector,
    HubSpotConnector
}